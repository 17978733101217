export default {
  init() {
    // JavaScript to be fired on the home page
    
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    
    /* eslint-disable */
    
    setTimeout(function() {
      $('#loading').addClass('loaded');
    }, 1400);
    setTimeout(function() {
      $('#loading').addClass('delete');
    }, 1500);
    
    $("a").on('click', function(event) {
      
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();
        
        // Store hash
        var hash = this.hash;
        
        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800, function(){
          
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      } // End if
    });
    
    AOS.init();
    
    var waypoint = new Waypoint({
      element: document.getElementById('About'),
      handler: function(direction) {
        console.log('Point');
        
        anime.timeline({loop: false})
        .add({
          targets: '.mlabout .word',
          scale: [14,1],
          opacity: [0,1],
          easing: "easeOutCirc",
          duration: 400,
          delay: (el, i) => 200 * i
        });
        
      },
      offset: 600
    })
    
    var waypoint = new Waypoint({
      element: document.getElementById('Skills'),
      handler: function(direction) {
        console.log('Point');
        
        anime.timeline({loop: false})
        .add({
          targets: '.mlskills .word',
          scale: [14,1],
          opacity: [0,1],
          easing: "easeOutCirc",
          duration: 400,
          delay: (el, i) => 200 * i
        });
        
      },
      offset: 600
    })
    
    var waypoint = new Waypoint({
      element: document.getElementById('Work'),
      handler: function(direction) {
        console.log('Point');
        
        anime.timeline({loop: false})
        .add({
          targets: '.mlwork .word',
          scale: [14,1],
          opacity: [0,1],
          easing: "easeOutCirc",
          duration: 400,
          delay: (el, i) => 200 * i
        });
        
      },
      offset: 600
    })
    
    var waypoint = new Waypoint({
      element: document.getElementById('Contact'),
      handler: function(direction) {
        console.log('Point');
        
        anime.timeline({loop: false})
        .add({
          targets: '.mlcontact .word',
          scale: [14,1],
          opacity: [0,1],
          easing: "easeOutCirc",
          duration: 400,
          delay: (el, i) => 200 * i
        });
        
      },
      offset: 600
    })
    
    
  },
};
